import Vue from "vue";
import store from "@/app/store/store";
import { apolloProvider } from "@/app/provider";

var _open = XMLHttpRequest.prototype.open;

function startViewRender( event: any )
{
    var self = event.target;

    if (
        self.readyState == 4 &&
        self.status == 200 &&
        self.responseURL.includes("/usersettings/cms-toolbar/")
    ) {
        setTimeout(()=>
        {
            let App = new Vue({
                store,
                apolloProvider
            }).$mount('#app');
        }, 500);
    }
};

XMLHttpRequest.prototype.open = function()
{
    this.addEventListener("readystatechange", startViewRender);
    _open.apply(this, arguments);
};
