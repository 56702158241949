import { Vue, Component, Watch } from "vue-property-decorator";

import { EventosModel } from "@/app/components/eventos/eventos.component.model";
import { QueryResult } from "vue-apollo/types/vue-apollo";

@Component
export default class EventosComponent extends Vue
{
    private eventos: object[] = [];

    private nome: string = "";
    private estado: string = "";
    private periodo: string[] = [];

    private get periodoNormalizado():string
    {
        return this.periodo.sort().join(",");
    }

    private modal: boolean = false;

    private ultimoCursor: string = "";
    private limite: number = 10;

    private carregarMais: boolean = true;
    private carregando: boolean = true;
    private primeiroCarregamento: boolean = true;

    constructor()
    {
        super();
    }

    public created()
    {
        this.controladorCarregamento();
    }

    private async controladorCarregamento():Promise<void>
    {
        await this.pegaEventos();

        this.primeiroCarregamento = false;
    }

    @Watch("periodo")
    private apenasDuasDatas():void
    {
        if ( this.periodo.length > 2 )
        {
            this.periodo.pop();
        }
    }

    private salvaDatasSelecionadas(item: any):void
    {
        //this.$refs.dialog.save( this.periodo );
        item.save( this.periodo );
        this.pegaEventos( true );
    }

    private pegaEventos( possuiFiltro: boolean = false ):Promise<void>
    {
        if ( possuiFiltro )
        {
            this.ultimoCursor = "";
        }

        this.carregando = true;

        return <Promise<void>>
        this.$apollo.query({
            query: EventosModel.pegaEventos,
            variables: {
                nome: this.nome,
                estado: this.estado,
                periodo: this.periodoNormalizado,
                limite: this.limite,
                cursor: this.ultimoCursor
            }
        })
        .then(
            ( resultados: QueryResult<any> )=>
            {
                if ( possuiFiltro )
                {
                    this.eventos = [];
                }

                for ( let evento of resultados.data.evento.edges )
                {
                    this.eventos = [
                        ...this.eventos,
                        evento.node
                    ]
                }

                this.ultimoCursor = resultados.data.evento.pageInfo.endCursor;
                this.carregarMais = resultados.data.evento.pageInfo.hasNextPage;
                this.carregando = false;
            },
            ( erros: QueryResult<any> )=>
            {
                console.error( erros );
            }
        )
        .catch(( erros: QueryResult<any> )=>
        {
            console.error( erros );
        })
    }
}

Vue.component("EventosComponent", EventosComponent);
