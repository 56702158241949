import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class HeaderInternaComponent extends Vue
{

    @Prop({ type: String, default: "" })
    private title!: string;

    constructor()
    {
        super();
    }
}

Vue.component("HeaderInternaComponent", HeaderInternaComponent);