import { Vue, Component, Prop } from "vue-property-decorator";
import { SwiperOptions } from "swiper";

@Component
export default class GaleriaComponent extends Vue
{
    @Prop({ type: Number, default: 0 })
    private tamanho!: 0 | 1;

    @Prop({ type: Number, default: 500 })
    private largura!: number;

    @Prop({ type: Boolean, default: true })
    private navegacaoSetas!: boolean;

    @Prop({ type: Boolean, default: false })
    private navegacaoControles!: boolean;

    @Prop({ type: String, default: "center" })
    private alinhamento!: "left" | "center" | "right";

    @Prop({ type: String, default: "slide" })
    private animacao!: "slide" | "fade";

    private swiperOption: SwiperOptions = {
        slidesPerView: 1,
        loop: false,
        autoHeight: false,
        setWrapperSize: true,
        watchOverflow: true
    };

    constructor()
    {
        super();
    }

    private get larguraNormalizada():string
    {
        return `${this.largura}px`;
    }

    private get combinarOpcoes():SwiperOptions
    {
        this.swiperOption = {
            ...this.swiperOption,
            effect: this.animacao
        };

        if ( this.navegacaoControles )
        {
            this.swiperOption = {
                ...this.swiperOption,
                pagination: {
                    el: ".cms-galeria__pagination",
                    clickable: true
                }
            }
        }

        if ( this.navegacaoSetas )
        {
            this.swiperOption = {
                ...this.swiperOption,
                navigation: {
                    nextEl: ".cms-galeria__next",
                    prevEl: ".cms-galeria__prev"
                }
            }
        }

        return this.swiperOption;
    }
}

Vue.component("GaleriaComponent", GaleriaComponent);
