import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class ContainerComponent extends Vue
{
    @Prop({ type: String, default: "#ffffff" })
    protected fundo!: string;

    @Prop({ type: String, default: "padrao" })
    protected largura!: "padrao" | "total-tela";

    constructor()
    {
        super();
    }
}
