import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class TituloComponent extends Vue
{
    @Prop({ type: Number, default: 1 })
    private relevancia!: 1 | 2 | 3 | 4 | 5 | 6;

    @Prop({ type: String, default: "Lorem ipsum dolor sit amet" })
    private titulo!: string;

    @Prop({ type: String, default: "#333333" })
    private cor!: string;

    @Prop({ type: Number, default: 36 })
    private fontDesk!: number;

    @Prop({ type: Number, default: 13 })
    private fontMobile!: number;

    @Prop({ type: Number, default: 400 })
    private estilo!: 400 | 500 | 700;

    @Prop({ type: String, default: "left" })
    private alinhamento!: "left" | "center" | "right";

    private larguraTela: number = 0;
    private readonly telaDesk: number = 1920;
    private readonly telaMobile: number = 320;

    public static get larguraTela():number
    {
        return window.innerWidth;
    }

    private get tituloRelevancia():object
    {
        return {
            template: `
                <h${this.relevancia}>
                </h${this.relevancia}>
            `,
        }
    }

    constructor()
    {
        super();
    }

    public created()
    {
        this.pegarLarguraTela();
    }

    private pegarLarguraTela():void
    {
        window.addEventListener(
            "resize",
            ( event: any )=>
            {
                this.fontScale( this.larguraTela );
            }
        );
    }

    private fontScale( larguraTela: number ):string
    {
        if ( larguraTela == this.fontDesk )
        {
            return `${this.fontDesk}px  `;
        }
        else if ( larguraTela == this.fontMobile )
        {
            return `${this.fontMobile}px`;
        }
        else
        {
            return `calc( ${this.fontMobile}px + (${this.fontDesk} - ${this.fontMobile}) * ( (100vw - ${this.telaMobile}px) / ( ${this.telaDesk} - ${this.telaMobile})) )`;
        }
    }
}

Vue.component("TituloComponent", TituloComponent );
