import { Vue, Component, Prop, Watch  } from "vue-property-decorator";
import { Validator } from "vee-validate";
import pt_BR from 'vee-validate/dist/locale/pt_BR';
import {defaultDataIdFromObject} from "apollo-cache-inmemory";
import { formFeedbackNegativoModel } from "./nac-feedback-negativo.component.model";
import { QueryResult } from "vue-apollo/types/vue-apollo";

declare let window : any;

@Component({
    $_veeValidate: { validator: "new" }
})
export default class NacFeedbackNegativoComponent extends Vue
{

    private siteUrl: string = location.protocol + '//' + location.host;


    @Prop({ default: [] })
    private info!: any;

    //private sent:boolean = false;

    private nome: string = this.info.nome;
    private email: string = this.info.email;
    private feedback: string = "";
    private telefone: string = this.info.telefone;
    private loading:boolean = false;
    private respondido:boolean = this.info.feedback_respondido;
    private id: number = this.info.id;
    private tipo: string = "nao";
    private atendimento: boolean = true;//this.info.atendimento;
       
    protected sendStatus: string = "info";
    protected snackbar: boolean = false; 

    protected nps = "";


    constructor()
    {
        super();
        this.$validator.localize("pt_BR", pt_BR);
    }


    protected enviar():void
    {   
        this.loading = true;
        this.$validator.validate()
         .then(( result: boolean )=>
        {
            if ( result )
            {
               this.sendStatus = "";
               this.$apollo.mutate({
                    mutation: formFeedbackNegativoModel.register,
                    variables: {
                        feedback: this.feedback,
                        nps: this.nps,
                        id: this.id,
                        tipoResposta: this.tipo,
                        atendimento: this.atendimento
                    }
                })
                .then(( data: any )=>
                {
                    this.respondido = true;
                    this.snackbar = false;
                })
                .catch(( error: any )=>
                {
                    this.sendStatus = "error";
                    this.snackbar = true;
                    this.loading = false;
                    
                });
                
                return;

            }else{

                this.sendStatus = "error";
                this.snackbar = true;
                this.loading = false;
                return;

            }
            
        })
        .catch(( error: any )=>
        {
            this.sendStatus = "error";
            this.loading = false;
            this.snackbar = true;
        })
    }
}

Vue.component("NacFeedbackNegativoComponent", NacFeedbackNegativoComponent);