import { Vue, Component, Prop } from "vue-property-decorator";
import {defaultDataIdFromObject} from "apollo-cache-inmemory";

@Component
export default class NacFormAtendimentoComponent extends Vue
{

    @Prop({ type: String, default: "" })
    protected titulo!: string;

    @Prop({ type: String, default: "" })
    protected texto!: string;
    
    constructor()
    {
        super();
    }
}

Vue.component("NacFormAtendimentoComponent", NacFormAtendimentoComponent);