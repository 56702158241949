import { render, staticRenderFns } from "./botao.component.vue?vue&type=template&id=23a5e4c1&"
import script from "./botao.component.ts?vue&type=script&lang=ts&"
export * from "./botao.component.ts?vue&type=script&lang=ts&"
import style0 from "./botao.component.sass?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "botao.component.vue"
export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VIcon } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
installComponents(component, {
  VBtn,
  VFlex,
  VIcon,
  VLayout,
})
