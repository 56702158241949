import gql from "graphql-tag";

export let NewsletterModel: any = {
    registrar: gql`
        mutation registrarNewsletter(
            $nome: String!,
            $email: String!
        ) {
            registrarAssinanteNewsletter (
                nome: $nome,
                email: $email
            )
            {
                erro
                status
            }
        }
    `
};
