import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class LinhaCreditoListaComponent extends Vue
{

    protected api_url: string = "/api/linha-credito/?limit=10";
    protected creditos: Array<String> = [];
    protected loading: boolean = false;
    protected page: number = 0;
    protected offset: number = 10;
    protected filter: string = "";
    protected proximaPagina: boolean = true;



    protected selectedUF: any = [];
    protected UFList: Array<object> = [
        { sigla: "AC", nome: "Acre" },
        { sigla: "AL", nome: "Alagoas" },
        { sigla: "AP", nome: "Amapá" },
        { sigla: "AM", nome: "Amazonas" },
        { sigla: "BA", nome: "Bahia" },
        { sigla: "CE", nome: "Ceará" },
        { sigla: "DF", nome: "Distrito Federal" },
        { sigla: "ES", nome: "Espírito Santo" },
        { sigla: "GO", nome: "Goiás" },
        { sigla: "MA", nome: "Maranhão" },
        { sigla: "MT", nome: "Mato Grosso" },
        { sigla: "MS", nome: "Mato Grosso do Sul" },
        { sigla: "MG", nome: "Minas Gerais" },
        { sigla: "PA", nome: "Pará" },
        { sigla: "PB", nome: "Paraíba" },
        { sigla: "PR", nome: "Paraná" },
        { sigla: "PE", nome: "Pernambuco" },
        { sigla: "PI", nome: "Piauí" },
        { sigla: "RJ", nome: "Rio de Janeiro" },
        { sigla: "RN", nome: "Rio Grande do Norte" },
        { sigla: "RS", nome: "Rio Grande do Sul" },
        { sigla: "RO", nome: "Rondônia" },
        { sigla: "RR", nome: "Roraima" },
        { sigla: "SC", nome: "Santa Catarina" },
        { sigla: "SP", nome: "São Paulo" },
        { sigla: "SE", nome: "Sergipe" },
        { sigla: "TO", nome: "Tocantins" }
    ];

    @Prop({ type: Array, default: "" })
    private bancos!: any;
    protected selectedBanco: Array<String> = [];


    @Prop({ type: Array, default: "" })
    private finalidades!: any;
    protected selectedFinalidade: Array<String> = [];


    @Prop({ type: Array, default: "" })
    private setores!: any;
    protected selectedSetor: Array<String> = [];



    protected headers: Array<object> = [
        {
            text: 'BANCO',
            align: 'left',
            sortable: false,
            value: 'name'
        },
        { text: 'SETOR', align: 'left', value: 'calories', sortable: false },
        { text: 'FINALIDADE', align: 'left', value: 'fat', sortable: false },
        { text: 'LINHA DE CRÉDITO', align: 'left', value: 'carbs', sortable: false },
        { text: 'DESCRIÇÃO', align: 'left', value: 'desc', sortable: false },
    ];



    constructor()
    {
        super();
    }

    public mounted()
    {
        this.queryConteudo(false);
    }

    get likesAllUF () {
        return this.selectedUF.length == this.UFList.length
    }
    get likesAllBanco () {
        return this.selectedBanco.length == this.bancos.length
    }
    get likesAllFinalidade () {
        return this.selectedFinalidade.length == this.finalidades.length
    }
    get likesAllSetor () {
        return this.selectedSetor.length == this.setores.length
    }



    get likesSomeUF () {
        return this.selectedUF.length > 0 && !this.likesAllUF
    }
    get likesSomeBanco () {
        return this.selectedBanco.length > 0 && !this.likesAllBanco
    }
    get likesSomeFinalidade () {
        return this.selectedFinalidade.length > 0 && !this.likesAllFinalidade
    }
    get likesSomeSetor () {
        return this.selectedSetor.length > 0 && !this.likesAllSetor
    }




    get iconUF () {
        if (this.likesAllUF) return 'mdi-close-box';
        if (this.likesSomeUF) return 'mdi-minus-box';
        return 'mdi-checkbox-blank-outline';
    }
    get iconBanco () {
        if (this.likesAllBanco) return 'mdi-close-box';
        if (this.likesSomeBanco) return 'mdi-minus-box';
        return 'mdi-checkbox-blank-outline';
    }
    get iconFinalidade () {
        if (this.likesAllFinalidade) return 'mdi-close-box';
        if (this.likesSomeFinalidade) return 'mdi-minus-box';
        return 'mdi-checkbox-blank-outline';
    }
    get iconSetor () {
        if (this.likesAllSetor) return 'mdi-close-box';
        if (this.likesSomeSetor) return 'mdi-minus-box';
        return 'mdi-checkbox-blank-outline';
    }





    private toggleUF () {
        this.$nextTick(() => {
            if (this.likesAllUF) {
                this.selectedUF = [];
            } else {
                this.selectedUF = this.UFList.slice();
            }
        })
    }
    private toggleBanco () {
        this.$nextTick(() => {
            if (this.likesAllBanco) {
                this.selectedBanco = [];
            } else {
                this.selectedBanco = this.bancos.slice();
            }
        })
    }
    private toggleFinalidade () {
        this.$nextTick(() => {
            if (this.likesAllFinalidade) {
                this.selectedFinalidade = [];
            } else {
                this.selectedFinalidade = this.finalidades.slice();
            }
        })
    }
    private toggleSetor () {
        this.$nextTick(() => {
            if (this.likesAllSetor) {
                this.selectedSetor = [];
            } else {
                this.selectedSetor = this.setores.slice()
            }
        })
    }


    protected queryConteudo(carregarMais):void
    {
        this.loading =  true;
        this.offset = this.page * 10;

        fetch(this.api_url + "&offset=" + this.offset + this.filter)
            .then(response => response.json())
            .then(( response: any )=>
                {
                    this.creditos = ( carregarMais ) ? this.creditos.concat(response.results) : response.results;
                    this.loading = false;

                    this.proximaPagina = ( response.next ) ? true : false;

                },
                ( response: any )=>
                {
                    console.error( "AgenciaNoticiasComponent Error Query", response );
                });

        this.filter = ""
    }

    private carregarMais () {
        this.page += 1;
        this.queryConteudo(true);
    }

    private sendFilter () {

        this.page = 0;

        if ( this.selectedUF ) {
            this.selectedUF.forEach(el => {
                this.filter += ("&uf__sigla=" + el)
            });
        }
        if ( this.selectedBanco ) {
            this.selectedBanco.forEach(el => {
                this.filter += ("&instituicoes=" + el)
            });
        }
        if ( this.selectedFinalidade ) {
            this.selectedFinalidade.forEach(el => {
                this.filter += ("&finalidade=" + el)
            });
        }
        if ( this.selectedSetor ) {
            this.selectedSetor.forEach(el => {
                this.filter += ("&setor=" + el)
            });
        }

        this.queryConteudo(false);

    }
    private clearFilters () {
        this.selectedUF = [];
        this.selectedBanco = [];
        this.selectedFinalidade = [];
        this.selectedSetor = [];
    }


}

Vue.component("LinhaCreditoListaComponent", LinhaCreditoListaComponent);