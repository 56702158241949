import { Vue, Component, Prop } from "vue-property-decorator";
import {defaultDataIdFromObject} from "apollo-cache-inmemory";

@Component
export default class NacDepoimentosComponent extends Vue
{

    protected autor: string = "Juli Goulart";
    protected texto: string = '”O Nac me ajudou encontrar a melhor linha de crédito. Super recomendo!”';
    protected cargo: string = "Proprietária da Indústia de Automóveis";
    //bullet inicial
    private bullet: number = 0;
    //bullet anterior setado
    private previous: number = 0;
    private show: boolean = true;

    protected testimonial: any = [
        {
            id: 1,
            number: 1,
            autor: "Marcelo Junqueira Pinto Carvalho", 
            cargo: "ARM Armazens Gerais e Logística LTDA",
            texto: '”A experiência foi extremamente positiva. Me apresentaram diversas alternativas, explicaram com precisão quais as vantagens de uma linha de crédito em relação a outra.”',
            class: ''
        },
        {
            id: 2,
            number: 2,
            autor: "Flor de Lis", 
            cargo: "empresa Cheiro da Flor",
            texto: '”Fui convidada pelo NAC para participar de uma rodada de crédito com bancos que ofereciam empréstimos para microempresas. Foi ótimo! Brinco que o NAC  é um anjo para mim, não só me mostrou a opção de acessar o crédito, como me orientou em todo o processo.”',
            class: ''
        },
        {
            id: 3,
            number: 3,
            autor: "Adriana Barbosa",
            cargo: "Empresária da Bahia",
            texto: '”Foi essencial e imprescindível o apoio do NAC.”',
            class: ''
        },
        {
            id: 4,
            number: 4,
            autor: "Wilanildo Pinheiro",
            cargo: "Empresário de Tocantins",
            texto: '”Excelentes profissionais, totalmente capacitados e dispostos a ajudar o empresário dentro da sua área de conhecimento”',
            class: ''
        },
        {
            id: 5,
            number: 5,
            autor: "Fábio Barros",
            cargo: "Empresário de Alagoas",
            texto: '”Fui atendido de forma muito atenciosa desde o primeiro contato com o NAC, na sequência fui convidado a participar de palestra e encaminhado para os bancos credores, sendo informado das linhas de crédito disponíveis para atender a minha necessidade.”',
            class: ''
        },
        {
            id: 6,
            number: 6,
            autor: "Eliania Silva",
            cargo: "Empresário de Tocantins",
            texto: '”Atenção, domínio do assunto e agilidade.”',
            class: ''
        },
        {
            id: 7,
            number: 7,
            autor: "Oisina Aragão",
            cargo: "Empresária do Maranhão",
            texto: '”Equipe de atendimento preparada e competente. Gostei muito do atendimento, tive todas as minhas dúvidas sanadas através do NAC.”',
            class: ''
        },
        {
            id: 8,
            number: 8,
            autor: "Vinícius Fernandes",
            cargo: "Empresário de São Paulo",
            texto: '”Muito bom atendimento e agilidade. Fico muito grato por apoio.”',
            class: ''
        },
        {
            id: 9,
            number: 9,
            autor: "Claudiney",
            cargo: "Empresário de Goiás",
            texto: '”Quero deixar registrado meus agradecimentos pela atenção e profissionalismo ao ser atendido pelo NAC.”',
            class: ''
        },
        {
            id: 10,
            number: 10,
            autor: "Francesco Caruso",
            cargo: "Empresário do Ceará",
            texto: '”A empresa fora atendida pelo gestor do NAC, que além de demonstrar bastante conhecimento no assunto, fora bastante solícito, esclarecendo os aspectos técnicos de viabilidade de acesso ao crédito. Os representantes da empresa agradecem o suporte ofertado.”',
            class: ''
        }
    ]
    
    constructor()
    {
        super();
    }

    protected clickBullet(e: any) {
        var txt = e.texto;
        var owner = e.autor;
        var work = e.cargo;

        this.texto = txt;
        this.autor = owner;
        this.cargo = work;
        this.bullet = e.id;

        this.testimonial.find(item => item.id === this.previous).class = '';
        this.testimonial.find(item => item.id === e.id).class = 'active';
        this.previous = e.id; 

    }
}

Vue.component("NacDepoimentosComponent", NacDepoimentosComponent);
