import { Vue, Component, } from "vue-property-decorator";

@Component
export default class CollapseComponent extends Vue
{
    constructor()
    {
        super();
    }

}

Vue.component("CollapseComponent", CollapseComponent);
