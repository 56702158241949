import { Vue, Component, Prop } from "vue-property-decorator";
import {defaultDataIdFromObject} from "apollo-cache-inmemory";

@Component
export default class NacBotWidgetComponent extends Vue
{
    
    private hover: boolean = false;

    constructor()
    {
        super();
    }

    /*protected triggerBot(){
        setTimeout(() => { 
            this.hover = true;
            console.log(this.hover + ' hover again');
        }, 500);
    }*/

}

Vue.component("NacBotWidgetComponent", NacBotWidgetComponent);