import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class NacCardsComponent extends Vue
{

    private linhascredito:any = [
        {
            id: 1,
            titulo: "Aumentar o seu negócio.", 
            descricao: "Existem linhas de crédito específicas para quem deseja reformar, ampliar, expandir ou modernizar as instalações do seu empreendimento.", 
            imagem: "icons/icon-grafico.svg",
            hover: "icons/icon-grafico-hover.svg"
        },
        {   
            id: 2,
            titulo: "Comprar novos equipamentos.", 
            descricao: "Caso a sua indústria precise de novas máquinas, equipamentos ou veículos para ampliar a sua capacidade de produção, há linhas de créditos dedicadas exclusivamente a essas necessidades.", 
            imagem: "icons/icon-compra.svg",
            hover: "icons/icon-compra-hover.svg"
        },
        {   
            id: 3,
            titulo: "Investir em inovação.", 
            descricao: "Se vocês decidiram que é hora de inovar, também existem recursos destinados ao financiamento e apoio à realização de projetos de pesquisa, desenvolvimento e inovação nas empresas brasileiras.", 
            imagem: "icons/icon-investir.svg",
            hover: "icons/icon-investir-hover.svg"
        }
    ]

    private selectedCard:Number = -1

    private cardIndex:Number = -1

    constructor()
    {
        super();
        
    }

    protected hoverCard(selectedIndex:any){
        this.selectedCard = selectedIndex
    }

    protected isSelected (cardIndex:any) {
        return this.selectedCard === cardIndex
    }

}

Vue.component("NacCardsComponent", NacCardsComponent);