import Vue from "vue";
import Vuetify from "vuetify/lib";
import "vuetify/src/stylus/app.styl";
import pt from "vuetify/src/locale/pt";

Vue.use(
    Vuetify,
    {
    theme: {
        primary: "#165092",
        secondary: "#ff5b5b",
        accent: "#24a3af",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107"
    },
    customProperties: true,
    iconfont: "mdi",
    lang: {
        locales: { pt },
        current: "pt"
    },
});
