import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class ImagemComponent extends Vue
{
    @Prop({ type: String, default: "https://picsum.photos/1920/1080?random" })
    private imagem!: string;

    @Prop({ type: String, default: "https://picsum.photos/1920/1080?random" })
    private imagemMobile!: string;

    @Prop({ type: String, default: "" })
    private legenda!: string;

    @Prop({ type: Number, default: 0 })
    private tamanho!: 0 | 1 | 2;

    @Prop({ type: Number, default: 500 })
    private larguraImagem!: number;

    @Prop({ type: Number, default: 500 })
    private alturaImagem!: number;

    @Prop({ type: Number, default: 500 })
    private larguraImagemMobile!: number;

    @Prop({ type: Number, default: 500 })
    private alturaImagemMobile!: number;

    @Prop({ type: Number, default: 500 })
    private larguraCustomizada!: number;

    @Prop({ type: String, default: "center" })
    private alinhamento!: "left" | "center" | "right";

    @Prop({ type: Boolean, default: true })
    private hasModal!: boolean;

    private modal: boolean = false;

    constructor()
    {
        super();
    }

    private mudaStatusModal():void
    {
        this.modal = !this.modal;
    }

    private get imagemAtual():string
    {
        if ( this.$vuetify.breakpoint.smAndDown )
        {
            return this.imagemMobile;
        }
        else
        {
            return this.imagem;
        }
    }

    private get proporcaoTela():number
    {
        if ( this.$vuetify.breakpoint.smAndDown )
        {
            return this.larguraImagemMobile / this.alturaImagemMobile;
        }
        else
        {
            if ( this.tamanho === 0 )
            {
                return this.larguraImagem / this.alturaImagem;
            }
            else
            {
                return 16/9;
            }
        }
    }

    private get proporcaoTelaEdge():string
    {
        if ( window.navigator.userAgent.indexOf("Edge") > -1 )
        {
            if ( this.$vuetify.breakpoint.smAndDown )
            {
                return `${(this.alturaImagemMobile / this.larguraImagemMobile) * 100}%`;
            }
            else
            {
                return `${(this.alturaImagem / this.larguraImagem) * 100}%`;
            }
        }

        return "0px";
    }

    private get tamanhoImagem():object
    {
        if (
            this.tamanho === 1 &&
            !this.$vuetify.breakpoint.smAndDown
        ) {
            return {
                width: `${this.larguraCustomizada}px`,
                height: "auto"
            };
        }
        else if (
            this.tamanho === 2 &&
            !this.$vuetify.breakpoint.smAndDown
        ) {
            return {
                width: `${this.larguraImagem}px`,
                height: `${this.alturaImagem}px`
            };
        }
        else
        {
            return {
                width: "100vw",
                height: "auto"
            };
        }
    }
}

Vue.component("ImagemComponent", ImagemComponent);
