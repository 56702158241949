import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class LinhaCreditoDetalhesComponent extends Vue
{   
    private dialog: boolean = false;

    @Prop({ default: [] })
    private info!: any;

    private nome: string = this.info.nome;
    private descricao: string = this.info.descricao;
    private descricao_longa: string = this.info.descricao_longa;
    private documentos: Array<String> = this.info.documentos;
    private tipo: Array<String> = this.info.tipo;
    private porte: Array<String> = this.info.porte;
    private setor: Array<String> = this.info.setor;
    private instituicoes: Array<String> = this.info.instituicoes;
    private uf: Array<String> = this.info.uf;
    private url: string = this.info.link;

    constructor()
    {
        super();
    }

    protected openListDoc(){
        this.dialog = true;
    }

    protected formatPrice(value: any) {
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }

}

Vue.component("LinhaCreditoDetalhesComponent", LinhaCreditoDetalhesComponent);