import gql from "graphql-tag";

export const ConteudosModel: any = {
    pegaTiposConteudos: gql`
        query pegaTiposConteudos( $id: Float )
        {
            conteudoTipos( pk: $id )
            {
                edges
                {
                    node
                    {
                        pk
                        id
                        nome
                    }
                    cursor
                }
            }
        }
    `,
    pegaTiposCategorias: gql`
        query pegaTiposCategorias( $tipoConteudo: Float )
        {
            conteudoTiposCategoria( tipo: $tipoConteudo )
            {
                edges
                {
                    node
                    {
                        pk
                        id
                        nome
                        categorias
                        {
                            edges
                            {
                                node
                                {
                                    id
                                    pk
                                    nome
                                }
                                cursor
                            }
                        }
                    }
                    cursor
                }
            }
        }
    `,
    pegaConteudos: gql`
        query pegaConteudos( $categorias: [ID], $tipos: [ID], $limite: Int!, $cursor: String )
        {
            conteudo( categorias: $categorias, tipo: $tipos, first: $limite, after: $cursor )
            {
                pageInfo
                {
                    hasNextPage
                    startCursor
                    endCursor
                }
                edges
                {
                    node
                    {
                        id
                        pk
                        tipo
                        {
                            id
                            nome
                            url
                        }
                        titulo
                        slug
                        descricao
                        publicado
                        dataPublicacao
                        imagemDestaque
                        imagemListagem
                        tags
                        url
                    }
                    cursor
                }
            }
        }
    `,
};
