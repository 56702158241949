import { render, staticRenderFns } from "./carrossel-slide.component.vue?vue&type=template&id=513acbf5&"
import script from "./carrossel-slide.component.ts?vue&type=script&lang=ts&"
export * from "./carrossel-slide.component.ts?vue&type=script&lang=ts&"
import style0 from "./carrossel-slide.component.sass?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "carrossel-slide.component.vue"
export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VImg } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VProgressCircular } from 'vuetify/lib'
installComponents(component, {
  VContainer,
  VFlex,
  VImg,
  VLayout,
  VProgressCircular,
})
