import { Vue, Component, Prop } from "vue-property-decorator";
import {defaultDataIdFromObject} from "apollo-cache-inmemory";

declare let window : any;

@Component
export default class BannerHomeComponent extends Vue
{
    @Prop({ type: Array, default: "" })
    private banner!: any;

    constructor()
    {
        super();
    }
}

Vue.component("BannerHomeComponent", BannerHomeComponent);