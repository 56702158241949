import { Vue, Component, Prop } from "vue-property-decorator";
import { SwiperOptions } from "swiper";

@Component
export default class CarrosselComponent extends Vue
{
    @Prop({ type: Boolean, default: true })
    private navegacaoSetas!: boolean;

    @Prop({ type: Boolean, default: false })
    private navegacaoControles!: boolean;

    @Prop({ type: Number, default: 6000 })
    private intervalo!: number;

    @Prop({ type: String, default: "slide" })
    private animacao!: "slide" | "fade";

    private swiperOptions: SwiperOptions = {
        slidesPerView: 1,
        loop: false,
        autoHeight: true,
        setWrapperSize: true,
        watchOverflow: true
    };

    constructor()
    {
        super();
    }

    private get combinarOpcoes():SwiperOptions
    {
        this.swiperOptions = {
            ...this.swiperOptions,
            effect: this.animacao
        };

        if ( this.navegacaoControles )
        {
            this.swiperOptions = {
                ...this.swiperOptions,
                pagination: {
                    el: ".cms-galeria__pagination",
                    clickable: true
                }
            };
        }

        if ( this.navegacaoSetas )
        {
            this.swiperOptions = {
                ...this.swiperOptions,
                navigation: {
                    nextEl: ".cms-galeria__next",
                    prevEl: ".cms-galeria__prev"
                }
            };
        }

        if ( this.intervalo )
        {
            this.swiperOptions = {
                ...this.swiperOptions,
                autoplay: {
                    delay: this.intervalo * 1000
                }
            };
        }

        return this.swiperOptions;
    }
}

Vue.component( "CarrosselComponent", CarrosselComponent );
