import {Vue, Component, Prop} from "vue-property-decorator";
import {defaultDataIdFromObject} from "apollo-cache-inmemory";

declare let window: any;

@Component
export default class NacAbrangenciaComponent extends Vue {
    protected api_url: string = "/abrangencia/";
    protected federacoes: any = [];
    created () {
        this.queryConteudo();
    }

    protected queryConteudo():void
        {

            fetch(this.api_url)
                .then(response => response.json())
                .then(( response: any )=>
                    {
                        this.federacoes = response;
                    },
                    ( response: any )=>
                    {
                        console.error( "AbrangenciaComponent Error Query", response );
                    });

        }

    private objFederacao: object = {};
    private showItem: Boolean = false;

    constructor() {
        super();
    }

    protected setFederacao(e: any) {
        //this.objFederacao.sigla = this.uf
        this.showItem = !this.showItem
        this.$nextTick(() => {
            setTimeout(() => {
                this.showItem = true
            }, 800)
        })
    }
}

Vue.component("NacAbrangenciaComponent", NacAbrangenciaComponent);
