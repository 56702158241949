import Vue from 'vue'
import { Component } from "vue-property-decorator";
import { ContatoModel } from "./contato.component.model";

@Component({
    $_veeValidate: {
        validator: "new"
    }
})
export default class ContatoComponent extends Vue
{
    private nome: string = "";
    private email: string = "";
    private assunto: string = "";
    private mensagem: string = "";

    private barraEstado: boolean = false;
    private estadoEnvio: string = "info";

    constructor()
    {
        super();
    }

    private enviarContato( event: Event ):void
    {
        event.preventDefault();

        this.$validator.validateAll()
        .then(( result: boolean ):void =>
        {
            if ( result )
            {
                this.estadoEnvio = "";

                this.$apollo.mutate({
                    mutation: ContatoModel.registrar,
                    variables: {
                        nome: this.nome,
                        email: this.email,
                        assunto: this.assunto,
                        mensagem: this.mensagem
                    }
                })
                .then(( data: any )=>
                {
                    this.estadoEnvio = "success";
                    this.barraEstado = true;

                    this.limparFormulario();
                })
                .catch(( error: any )=>
                {
                    console.error("ContatoComponent enviarContato mutation", error);

                    this.estadoEnvio = "error";
                    this.barraEstado = true;
                });

                return;
            }

            this.estadoEnvio = "error";
            return;
        })
        .catch(( error: any )=>
        {
            console.error("ContatoComponent enviarContato", error);

            this.estadoEnvio = "error";
            this.barraEstado = true;
        });
    }

    private limparFormulario():void
    {
        setTimeout(():void =>
        {
            this.$validator.reset();

            this.nome = "";
            this.email = "";
            this.assunto = "";
            this.mensagem = "";

            this.estadoEnvio = "info";
            this.barraEstado = false;
        }, 6000);
    }
}
 Vue.component("ContatoComponent", ContatoComponent);
