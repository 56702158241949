import { render, staticRenderFns } from "./newsletter.component.vue?vue&type=template&id=651c3332&scoped=true&"
import script from "./newsletter.component.ts?vue&type=script&lang=ts&"
export * from "./newsletter.component.ts?vue&type=script&lang=ts&"
import style0 from "./newsletter.component.sass?vue&type=style&index=0&id=651c3332&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "651c3332",
  null
  
)

component.options.__file = "newsletter.component.vue"
export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib'
import { VContainer } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VIcon } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VSnackbar } from 'vuetify/lib'
import { VTextField } from 'vuetify/lib'
installComponents(component, {
  VBtn,
  VContainer,
  VFlex,
  VIcon,
  VLayout,
  VSnackbar,
  VTextField,
})
