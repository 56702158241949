import VueApollo from "vue-apollo";
import fetch from "unfetch";

import { ApolloClient } from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";

import store from "@/app/store/store"
store.dispatch("setEnvironment");

// HTTP connection to the API
const httpLink = new HttpLink({
    uri: store.state.graphql,
    fetch: fetch
});

// Cache implementation
// Removido manualmente o __typename das queries via Apollo
const cache = new InMemoryCache({addTypename: false});


// Create the apollo client
const apolloClient = new ApolloClient({
    link: httpLink,
    cache,
});

// Export provider
export const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
});
