import { render, staticRenderFns } from "./contato.component.vue?vue&type=template&id=15a28ae2&scoped=true&"
import script from "./contato.component.ts?vue&type=script&lang=ts&"
export * from "./contato.component.ts?vue&type=script&lang=ts&"
import style0 from "./contato.component.sass?vue&type=style&index=0&id=15a28ae2&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15a28ae2",
  null
  
)

component.options.__file = "contato.component.vue"
export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib'
import { VContainer } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VIcon } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VSnackbar } from 'vuetify/lib'
import { VTextField } from 'vuetify/lib'
import { VTextarea } from 'vuetify/lib'
installComponents(component, {
  VBtn,
  VContainer,
  VFlex,
  VIcon,
  VLayout,
  VSnackbar,
  VTextField,
  VTextarea,
})
