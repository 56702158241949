import { Vue, Component, Prop } from "vue-property-decorator";
import {defaultDataIdFromObject} from "apollo-cache-inmemory";

@Component
export default class NacPorqueConsultarComponent extends Vue
{
    protected lists: any = [
        // {
        //     id: 1,
        //     icone: "icons/icon-ai.svg", 
        //     item: "Inteligência Artificial", 
        //     texto: "O NAC tem atendimento eletrônico 24h que conversa com você e recomenda rapidamente a melhor linha de crédito de acordo com a necessidade e perfil da sua empresa."
        // },
        /*{
            id: 2,
            icone: "icons/icon-custo.svg", 
            item: "Sem custo adicional", 
            texto: "O NAC é um serviço oferecido e subsidiado pela <b>Confederação Nacional da Indústria (CNI)</b>, por isso não tem nenhum custo adicional para o empresário e neutralidade de relações."
        },*/
        {
            id: 2,
            icone: "icons/icon-contato.svg", 
            item: "Acesso às linhas de crédito disponíveis em todo o Brasil",
            texto: "Os especialistas do NAC estão em contato constante com as mais diversas instituições financeiras do Brasil para se manterem sempre atualizados sobre as ofertas de crédito disponíveis."
        },
        {
            id: 3,
            icone: "icons/icon-empresas.svg", 
            item: "Mais de 16 mil empresas atendidas",
            texto: "Desde 2015, o NAC já contribuiu com o desenvolvimento de mais de 16 mil empresas brasileiras."
        },
        {
            id: 4,
            icone: "icons/icon-estados.svg", 
            item: "Atendimento pessoal em mais de 26 estados", 
            texto: "As Federações da Indústria contam com uma equipe de especialistas em crédito do NAC disponível para você tirar todas as suas dúvidas e apoiar a sua empresa presencialmente."
        }
    ]

    constructor()
    {
        super();
        
    }
}

Vue.component("NacPorqueConsultarComponent", NacPorqueConsultarComponent);
