import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class ParallaxComponent extends Vue
{
    @Prop({ type: String, default: "https://picsum.photos/1920/1080?random" })
    private imagem!: string;

    @Prop({ type: String, default: "https://picsum.photos/1920/1080?random" })
    private imagemMobile!: string;

    private imagemAtual: string = "";

    constructor()
    {
        super();
    }
}

Vue.component( "ParallaxComponent", ParallaxComponent );
