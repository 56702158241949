var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"cms-botao",attrs:{"align-center":"","justify-start":_vm.alinhamento === 'left',"justify-center":_vm.alinhamento === 'center',"justify-end":_vm.alinhamento === 'right'}},[_c('v-btn',{staticClass:"cms-botao__this ma-0",attrs:{"fab":_vm.mobile,"flat":_vm.flat,"depressed":!_vm.depressed,"outline":_vm.outline,"round":_vm.round && !_vm.mobile,"small":_vm.tamanho === 'small',"large":_vm.tamanho === 'large',"href":_vm.link,"color":_vm.corBotao,"download":_vm.isFile}},[_c('v-layout',{attrs:{"justify-center":""}},[(_vm.isFile && !_vm.mobile)?_c('v-flex',[(_vm.extension === 'pdf')?_c('v-icon',{class:{
                            'mr-2': _vm.tamanho === 'small',
                            'mr-3': _vm.tamanho === 'medium',
                            'mr-4': _vm.tamanho === 'large'
                        },attrs:{"color":_vm.corTexto,"small":_vm.tamanho === 'medium' || _vm.tamanho === 'large'}},[_vm._v("\n                    fa-file-pdf\n                ")]):_vm._e()],1):_vm._e(),(!_vm.mobile)?_c('v-flex',[_c('span',{staticClass:"cms-botao__texto",style:({ color: _vm.corTexto })},[_vm._v("\n                    "+_vm._s(_vm.texto)+"\n                ")])]):_vm._e(),(_vm.isFile && !_vm.mobile)?_c('v-flex',{class:{
                        'cms-botao__divisor': true,
                        'mx-2': _vm.tamanho === 'small',
                        'mx-3': _vm.tamanho === 'medium',
                        'mx-4': _vm.tamanho === 'large'
                    }}):_vm._e(),(_vm.isFile && !_vm.mobile)?_c('v-flex',[_c('v-icon',{attrs:{"color":_vm.corTexto,"small":_vm.tamanho === 'medium' || _vm.tamanho === 'large'}},[_vm._v("\n                    fa-download\n                ")])],1):_vm._e(),(!_vm.isFile && _vm.mobile)?_c('v-icon',{attrs:{"color":_vm.corTexto,"small":_vm.tamanho === 'medium' || _vm.tamanho === 'large'}},[_vm._v("\n                fa-link\n            ")]):_vm._e(),(_vm.isFile && _vm.mobile)?_c('v-icon',{attrs:{"color":_vm.corTexto,"small":_vm.tamanho === 'medium' || _vm.tamanho === 'medium'}},[_vm._v("\n                fa-download\n            ")]):_vm._e()],1)],1),(_vm.mobile)?_c('span',{staticClass:"ml-3",style:({ color: _vm.corBotao })},[_vm._v("\n        "+_vm._s(_vm.texto)+"\n    ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }