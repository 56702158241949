import gql from "graphql-tag";

export let NacFaqComponentModel: any = {
    faqs: {
        query: gql`
            query getFAQ {
                faqs{
                    edges{
                        node{
                            id
                            pergunta
                            resposta
                            url
                        }
                    }
                }
            }
        `,
        loadingKey: "loading",
        update: (data) => {
            let duvidas = data.edges[0]
            return duvidas;
        }
    }
};
