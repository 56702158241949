import Vue from "vue";
import store from "@/app/store/store";

import "@/app/helpers/django";
import "@/app/plugins/vue-awesome-swiper/vue-awesome-swiper";
import "@/app/plugins/vee-validate/vee-validate";
import "@/app/plugins/vuetify";
import "@/app/plugins/apollo";
import "@/app/plugins/v-money/v-money";

import { apolloProvider } from "@/app/provider";

import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";

import "@/app/app.component";
import "@/app/components/components";
import "@/app/pages/pages";

Vue.config.productionTip = false;

let App = new Vue({
    store,
    apolloProvider,
})
.$mount("#app");
