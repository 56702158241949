import { Vue, Component } from "vue-property-decorator";

@Component
export default class AbaContentComponent extends Vue
{
    constructor()
    {
        super();
    }
}

Vue.component("AbaContentComponent", AbaContentComponent);
