import { Vue, Component, Prop } from "vue-property-decorator";
import {defaultDataIdFromObject} from "apollo-cache-inmemory";

declare let window : any;

@Component
export default class NacBannerHomeComponent extends Vue
{
    //@Prop({ type: Array, default: "" })
    //private banner!: any;

    constructor()
    {
        super();
    }
}

Vue.component("NacBannerHomeComponent", NacBannerHomeComponent);