var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
        'cms-video': true,
        'cms-video--center': _vm.alinhamento === 'center',
        'cms-video--left': _vm.alinhamento === 'left' && !_vm.viewSize < 960,
        'cms-video--right': _vm.alinhamento === 'right' && !_vm.viewSize < 960
    },style:({
        width: _vm.mudaLarguraImagem(),
        paddingTop: _vm.aspectRatioEdge()
    })},[(!_vm.hasModal)?_c('v-responsive',{attrs:{"aspect-ratio":_vm.aspectRatio}},[_c('iframe',{staticClass:"cms-video__iframe",attrs:{"src":_vm.video,"frameborder":"0","allow":"autoplay; encrypted-media","allowfullscreen":""}})]):_vm._e(),(_vm.hasModal)?_c('v-img',{style:({ width: _vm.mudaLarguraImagem() }),attrs:{"src":_vm.imagem,"lazy-src":_vm.imagem,"aspect-ratio":_vm.aspectRatio}},[_c('v-layout',{attrs:{"slot":"placeholder","fill-height":"","align-center":"","justify-center":"","ma-0":""},slot:"placeholder"},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1),_c('v-layout',{staticClass:"cms-video__overlay",attrs:{"fill-height":"","align-center":"","justify-center":"","ma-0":""},on:{"click":_vm.mudaStatusModal}},[_c('v-btn',{attrs:{"type":"a","outline":"","large":"","fab":"","color":"#ffffff"}},[_c('v-icon',{staticClass:"cms-video__icon"},[_vm._v("\n                    mdi-play\n                ")])],1)],1)],1):_vm._e(),(_vm.hasModal && _vm.modal)?_c('v-dialog',{attrs:{"scrollable":"","full-width":""},model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-responsive',{style:({
                paddingTop: _vm.aspectRatioEdge()
            }),attrs:{"aspect-ratio":_vm.aspectRatio}},[_c('iframe',{staticClass:"cms-video__iframe",attrs:{"src":_vm.video,"frameborder":"0","allow":"autoplay; encrypted-media","allowfullscreen":""}})])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }