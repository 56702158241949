import { Vue, Component, Prop } from "vue-property-decorator";
import {defaultDataIdFromObject} from "apollo-cache-inmemory";

@Component
export default class NacEtapasComponent extends Vue
{
    
    constructor()
    {
        super();
    }
}

Vue.component("NacEtapasComponent", NacEtapasComponent);