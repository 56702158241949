import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class LocalizacaoComponent extends Vue
{
    protected url: string = "https://www.google.com/maps/embed/v1/place?key=${CHAVE}&q=${ENDERECO}";

    @Prop({ type: String, default: "CICB+-+Centro+Internacional+de+Convenções+do+Brasil+-+SCES+Trecho+2,+Conjunto+63,+Lote+50+-+Asa+Sul+-+Brasília,+DF+-+CEP:+70.200-002" })
    protected endereco!: string;

    @Prop({ type: String, default: "AIzaSyCBLZNFtyOWGbpczwNbNP_jy9ZCUa9kI9M" })
    protected chave!: string;

    @Prop({ type: String, default: "500px" })
    protected altura!: string;

    constructor()
    {
        super();
    }

    protected caminhoIframe():string
    {
        let iframe: string = "";

        iframe = this.url.replace("${CHAVE}", this.chave);
        iframe = iframe.replace("${ENDERECO}", this.endereco);

        return iframe;
    }
}

Vue.component( "LocalizacaoComponent", LocalizacaoComponent );
