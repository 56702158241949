import { render, staticRenderFns } from "./abas.component.vue?vue&type=template&id=7ec3c15b&"
import script from "./abas.component.ts?vue&type=script&lang=ts&"
export * from "./abas.component.ts?vue&type=script&lang=ts&"
import style0 from "./abas.component.sass?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "abas.component.vue"
export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTabs } from 'vuetify/lib'
import { VTabsItems } from 'vuetify/lib'
import { VTabsSlider } from 'vuetify/lib'
installComponents(component, {
  VTabs,
  VTabsItems,
  VTabsSlider,
})
