import { render, staticRenderFns } from "./header-interna.component.vue?vue&type=template&id=19c59fd4&scoped=true&"
import script from "./header-interna.component.ts?vue&type=script&lang=ts&"
export * from "./header-interna.component.ts?vue&type=script&lang=ts&"
import style0 from "./header-interna.component.sass?vue&type=style&index=0&id=19c59fd4&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19c59fd4",
  null
  
)

component.options.__file = "header-interna.component.vue"
export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib'
installComponents(component, {
  VContainer,
})
