import { Vue, Component, Prop } from "vue-property-decorator";
import {defaultDataIdFromObject} from "apollo-cache-inmemory";
import { NacNewsletterModel } from "./nac-newsletter.component.model";

@Component
export default class NacNewsletterComponent extends Vue
{
    private nome: string = "";
    private email: string = "";
    //Variável que exibe mensagem de sucesso caso seja enviado
    private assinado: boolean = false;
    //Exibe/remove loading do botão
    private loading: boolean = false;
    
    constructor()
    {
        super();
    }

    protected enviar():void
    {
        this.loading = true;
        this.$validator.validateAll()
        .then(( result: boolean ):void =>
        {
            if ( result )
            {

                this.$apollo.mutate({
                    mutation: NacNewsletterModel.registrar,
                    variables: {
                        email: this.email
                    }
                })
                .then(( response: any ):void =>
                {
                    console.log(response.data.registrarAssinanteNewsletter.status);
                    if(response.data.registrarAssinanteNewsletter.status){
                        this.assinado = response.data.registrarAssinanteNewsletter.status;
                        this.loading = false;
                    }else{
                        this.errors.add({
                            field: 'email',
                            msg: 'E-mail já cadastrado :)'
                          })
                        this.loading = false;
                    }

                })
                .catch(( error: any ):void =>
                {
                    console.error( "NewsletterComponent enviar mutation", error );
                    this.loading = false;

                });

                return;
            }

            //console.log('erro no envio');
            this.loading = false;
            return;
        })
        .catch(( error: any ):void =>
        {
            console.error("NewsletterComponent enviar", error);

        })
    }
}

Vue.component("NacNewsletterComponent", NacNewsletterComponent);