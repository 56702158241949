import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Validator } from "vee-validate";
import pt_BR from 'vee-validate/dist/locale/pt_BR';
import {defaultDataIdFromObject} from "apollo-cache-inmemory";
import { formFeedbackPositivoModel } from "./nac-feedback-positivo.component.model";
import { QueryResult } from "vue-apollo/types/vue-apollo";
import {Money} from 'v-money';

declare let window : any;

@Component({
    $_veeValidate: { validator: "new" }
})
export default class NacFeedbackPositivoComponent extends Vue
{

    private siteUrl: string = location.protocol + '//' + location.host;


    @Prop({ default: [] })
    private info!: any;

    //private sent:boolean = false;
    //private moeda: any = this.info.montante;
    private montante: any = 0.00;
    private money:any = {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2
      }

    private nome: string = this.info.nome;
    private email: string = this.info.email;
    private feedback: string = "";
    private telefone: string = this.info.telefone;
    private loading:boolean = false;
    private respondido:boolean = this.info.feedback_respondido;
    private id: number = this.info.id;
    private tipo: string = "sim";
    private atendimento: boolean = this.info.atendimento;
       
    protected sendStatus: string = "info";
    protected snackbar: boolean = false; 

    protected nps = "";

    private lista: object[] = [];
    protected sels: object[] = [];

    constructor()
    {
        super();
        this.$validator.localize("pt_BR", pt_BR);
        this.queryInstituicoes();

    }

    private queryInstituicoes():Promise<void>
    {

        return <Promise<void>>
        this.$apollo.query({
            query: formFeedbackPositivoModel.instituicoes
        })
        .then(
            ( resultados: QueryResult<any> )=>
            {

                for ( let instituicoes of resultados.data.instituicoes.edges )
                {
                    this.lista = [
                        ...this.lista,
                        instituicoes.node
                    ]
                }

            },
            ( erros: QueryResult<any> )=>
            {
                console.error( erros );
            }
        )
        .catch(( erros: QueryResult<any> )=>
        {
            console.error( erros );
        })
    }

    protected enviar():void
    {   
        this.loading = true;
        console.log(this.sels);
        this.$validator.validate()
         .then(( result: boolean )=>
        {
            if ( result )
            {
               this.sendStatus = "";
               this.$apollo.mutate({
                    mutation: formFeedbackPositivoModel.register,
                    variables: {
                        feedback: this.feedback,
                        montante: this.montante,
                        nps: this.nps,
                        id: this.id,
                        banco: this.sels ? [this.sels] : [],
                        tipoResposta: this.tipo,
                        atendimento: this.atendimento
                    }
                })
                .then(( data: any )=>
                {
                    this.respondido = true;
                    this.snackbar = false;
                })
                .catch(( error: any )=>
                {
                    console.log(error);
                    
                    this.sendStatus = "error";
                    this.snackbar = true;
                    this.loading = false;
                    
                });
                
                return;
                
            }else{
                
                this.sendStatus = "error";
                this.snackbar = true;
                this.loading = false;
                return;
                
            }
            
        })
        .catch(( error: any )=>
        {
            console.log(error);
            this.sendStatus = "error";
            this.loading = false;
            this.snackbar = true;
        })
    }
}

Vue.component("NacFeedbackPositivoComponent", NacFeedbackPositivoComponent);