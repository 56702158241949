import { render, staticRenderFns } from "./eventos.component.vue?vue&type=template&id=24af2b4e&scoped=true&"
import script from "./eventos.component.ts?vue&type=script&lang=ts&"
export * from "./eventos.component.ts?vue&type=script&lang=ts&"
import style0 from "./eventos.component.sass?vue&type=style&index=0&id=24af2b4e&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24af2b4e",
  null
  
)

component.options.__file = "eventos.component.vue"
export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib'
import { VCard } from 'vuetify/lib'
import { VCardTitle } from 'vuetify/lib'
import { VCombobox } from 'vuetify/lib'
import { VContainer } from 'vuetify/lib'
import { VDatePicker } from 'vuetify/lib'
import { VDialog } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VIcon } from 'vuetify/lib'
import { VImg } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VProgressCircular } from 'vuetify/lib'
import { VResponsive } from 'vuetify/lib'
import { VSpacer } from 'vuetify/lib'
installComponents(component, {
  VBtn,
  VCard,
  VCardTitle,
  VCombobox,
  VContainer,
  VDatePicker,
  VDialog,
  VFlex,
  VIcon,
  VImg,
  VLayout,
  VProgressCircular,
  VResponsive,
  VSpacer,
})
