import { Vue, Component, Prop } from "vue-property-decorator";
import {defaultDataIdFromObject} from "apollo-cache-inmemory";
import { NacFaqComponentModel } from "@/app/components/nac-faq/nac-faq.component.model";

@Component
export default class NacFaqComponent extends Vue
{
    private duvidas: object[] = [];

    /*private duvidas:any = [
        {
            id: 1,
            pergunta: "Existe financiamento para empresa em implantação?", 
            resposta: "Sim, existe o financiamento para implantação. Nesse caso o empresário deve apresentar o projeto de viabilidade econômica financeira e as garantias. Geralmente as exigências são maiores sendo fundamental o histórico de relacionamento do cliente com o banco e  bem a demonstração da experiência gerencial e no ramo de atividade que irá investir. ", 
        },
        {   
            id: 2,
            pergunta: "É possível adquirir a franquia de uma empresa com o financiamento do banco?", 
            resposta: "É possível sim, entretanto nestas operações a contrapartida exigida do cliente varia entre 50% e 60% do valor do projeto.  Geralmente é avaliada a performance da empresa franqueadora que deve possuir avaliação de risco de crédito na instituição financeira e atender alguns requisitos que variam de banco para banco. Sendo que a marca não é financiada.", 
        },
        {   
            id: 3,
            pergunta: "Quero comprar o imóvel onde a minha empresa funciona. O banco financia?", 
            resposta: "Sim é possível financiar. Normalmente o financiamento é de acordo com a capacidade de pagamento da empresa ou com a viabilidade do projeto de investimento e a garantia é a alienação fiduciária do imóvel. Ou seja, o imóvel é dado como garantia de pagamento e financiamento, enquanto o financiamento não é quitado, a empresa tem o direito de uso do imóvel.", 
        },
        {   
            id: 4,
            pergunta: "O banco exige a comprovação do recurso próprio como contrapartida no financiamento?", 
            resposta: "Sim, o banco exige a comprovação do recurso próprio na contrapartida do financiamento quando não se financia o limite de 100%. A comprovação pode ser por meio de depósito na conta do cliente na banco financiador, ou por meio de comprovação de pagamento ao fornecedor, com a apresentação da nota fiscal ou recibo.", 
        },
        {   
            id: 5,
            pergunta: "Como funciona a execução da garantia em um financiamento?", 
            resposta: "No caso de inadimplência total ou parcial da dívida, depois de esgotadas todas as alternativas de negociação extrajudicial entre o banco e o cliente devedor, o banco entra com um processo de execução da dívida junto à justiça. A sentença sendo favorável ao banco, ele poderá executar as garantias oferecidas no contrato de financiamento. A justiça remete para leilão e com o resultado do leilão se realiza a execução da dívida.", 
        }
        ,
        {   
            id: 6,
            pergunta: "Há financiamento para pagar dívidas?", 
            resposta: "Existem linhas de crédito específicas para quem deseja reformar, ampliar, expandir ou modernizar as instalações do seu empreendimento.", 
        }
        ,
        {   
            id: 7,
            pergunta: "Como funciona a liquidação antecipada de uma dívida no banco?", 
            resposta: "A liquidação antecipada pode ser feita com a utilização de recursos próprios ou por transferência de recursos a partir de outro banco. Clientes que tenham tomado empréstimos de bancos podem solicitar a liquidação antecipada do débito, total ou parcialmente com redução proporcional dos juros. O banco deve conceder desconto pela antecipação do pagamento, de acordo com o prazo de antecipação das parcelas.", 
        }
        ,
        {   
            id: 8,
            pergunta: "Podem ser cobradas tarifas para a transferência de crédito de um banco para outro?", 
            resposta: "Não é vedada a cobrança de tarifas para a transferência de crédito de um banco para outro. Entretanto os custos da operação de liquidação antecipada não podem ser repassados ao cliente pessoa física, Microempreendedor individual (MEI), microempresa e empresa de pequeno porte, nem sob a forma de tarifa. 
A exceção a essa regra são operações contratadas antes de 10.12.2007, que podem ser tarifadas, caso esteja regularmente estabelecida em contrato.
Para empresas de médio e de grande porte, não há regra proibindo a cobrança de tarifa para a liquidação antecipada.", 
        }
        ,
        {   
            id: 9,
            pergunta: "Tenho que comprar outros produtos do banco para conseguir obter o crédito?", 
            resposta: "O cliente não tem que comprar, essa é uma prática proibida pelo BACEN, Resolução 2.892/2001, entretanto se o cliente necessitar de serviços e produtos úteis  e com condições que tragam vantagem para empresa isso é chamado de reciprocidade e geralmente é considerado pelos bancos nas avaliações de crédito.", 
        }
        ,
        {   
            id: 10,
            pergunta: "O que pode ser financiado com o capital de giro?", 
            resposta: "Podem ser financiadas as necessidades de curto prazo, os eventuais déficits de caixa, o financiamento das vendas e pagamento das despesas, da folha de pagamento, da aquisição de insumos, de matérias-primas ou compras de mercadorias para constituição de estoque e qualquer emergência de curto prazo.", 
        }
        ,
        {   
            id: 11,
            pergunta: "Posso financiar veículos com capital de giro?", 
            resposta: "O capital de giro não pode ser associado à aquisição de veículos.", 
        }
        ,
        {   
            id: 12,
            pergunta: "Posso financiar veículo de passeio no BB Crédito Empresa?", 
            resposta: "Sim, veículos de passeio destinados ao transporte de pessoas e suas bagagens, exceto veículos esportivos, podem ser financiados no BB Crédito Empresa, desde que essenciais ao exercício da empresa.", 
        }
        ,
        {   
            id: 13,
            pergunta: "Quem pode ser o portador do Cartão BNDES?", 
            resposta: "O portador do Cartão BNDES poderá ser um dirigente ou funcionário da ativa na empresa (com vínculo empregatício), indicado pela empresa no formulário de solicitação do Cartão BNDES.", 
        }
        ,
        {   
            id: 14,
            pergunta: "Qual a forma de pagamento do BNDES FINAME?", 
            resposta: "Capital: as prestações são mensais e sucessivas, cada uma delas no valor do principal da dívida dividido pelo número de parcelas de amortização, vencendo a primeira no dia 15 do mês subsequente ao término do prazo de carência. Encargos financeiros: durante a carência os juros são pagos trimestralmente. Na fase de amortização, os juros são pagos mensalmente junto com as amortizações do principal.", 
        }
    ]*/
    
    constructor()
    {
        super();

        this.queryFAQ();
    }

    private queryFAQ():void
    {
        this.$apollo
        .query({
            query: NacFaqComponentModel.faqs.query
        })
        .then(( results )=>
        {
            this.duvidas = results.data.faqs.edges;
            console.log(this.duvidas);
        })
        .catch(( error )=>
        {
            console.error( error );
        })
    }

}

Vue.component("NacFaqComponent", NacFaqComponent);