import gql from "graphql-tag";

export let NacNewsletterModel: any = {
    registrar: gql`
        mutation registrarNewsletter(
            $email: String!
        ) {
            registrarAssinanteNewsletter (
                email: $email
            )
            {
                erro
                status
            }
        }
    `
};
