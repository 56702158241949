import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class CarrosselSlideComponent extends Vue
{
    @Prop({ type: String, default: "" })
    protected titulo!: string;

    @Prop({ type: String, default: "" })
    protected descricao!: string;

    @Prop({ type: String, default: "https://picsum.photos/1920/1080?random" })
    protected imagem!: string;

    @Prop({ type: Number, default: 1920 })
    protected larguraImagem!: number;

    @Prop({ type: Number, default: 1080 })
    protected alturaImagem!: number;

    @Prop({ type: String, default: "https://picsum.photos/1920/1080?random" })
    protected imagemMobile!: string;

    @Prop({ type: Number, default: 750 })
    protected larguraImagemMobile!: number;

    @Prop({ type: Number, default: 442 })
    protected alturaImagemMobile!: number;

    @Prop({ type: String, default: "" })
    protected imagemDestaque!: string;

    @Prop({ type: Number, default: 0 })
    protected larguraImagemDestaque!: number;

    @Prop({ type: Number, default: 0 })
    protected alturaImagemDestaque!: number;

    @Prop({ type: String, default: "" })
    protected link!: string;

    constructor()
    {
        super();
    }

    private get imagemAtual():string
    {
        if ( this.$vuetify.breakpoint.mdAndDown )
        {
            return this.imagemMobile;
        }

        return this.imagem;
    }

    private get proporcaoImagem():number
    {
        if ( this.$vuetify.breakpoint.smAndDown )
        {
            return this.larguraImagemMobile / this.alturaImagemMobile;
        }
        else
        {
            return this.larguraImagem / this.alturaImagem;
        }
    }

    private get proporcaoDestaque():number
    {
        return this.larguraImagemDestaque / this.alturaImagemDestaque;
    }

    private get proporcaoImagemEdge():string
    {
        if ( window.navigator.userAgent.indexOf("Edge") > -1 )
        {
            if ( this.$vuetify.breakpoint.smAndDown )
            {
                return `${(this.alturaImagemMobile / this.larguraImagemMobile) * 100}%`;
            }
            else
            {
                return `${(this.alturaImagem / this.larguraImagem) * 100}%`;
            }
        }

        return "0px";
    }

    private cliqueCarrossel( event: Event ):void
    {
        if ( this.link.length )
        {
            if ( this.link.indexOf( window.location.origin ) === -1 )
            {
                window.location.href = this.link;
                return;
            }

            window.open( this.link );
            return
        }
        else
        {
            event.preventDefault();
            return;
        }
    }
}

Vue.component("CarrosselSlideComponent", CarrosselSlideComponent);
