import gql from "graphql-tag";

export const EventosModel: any = {
    pegaEventos: gql`
        query pegaEventos(
            $nome:String,
            $estado: String,
            $periodo: String,
            $limite: Int,
            $cursor: String
        ) {
            evento(
                nome_Icontains: $nome,
                estado: $estado,
                dataRange: $periodo,
                first: $limite,
                after: $cursor
            ) {
                pageInfo
                {
                    hasNextPage
                    endCursor
                }
                edges
                {
                    node
                    {
                        id
                        pk
                        nome
                        local
                        estado
                        estado
                        endereco
                        link
                        url
                        descricao
                        imagem
                        datahora
                        {
                            data
                            horaInicio
                            horaFim
                        }
                    }
                    cursor
                }
            }
        }
    `
};
