import { render, staticRenderFns } from "./localizacao.component.vue?vue&type=template&id=250c447a&scoped=true&"
import script from "./localizacao.component.ts?vue&type=script&lang=ts&"
export * from "./localizacao.component.ts?vue&type=script&lang=ts&"
import style0 from "./localizacao.component.sass?vue&type=style&index=0&id=250c447a&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "250c447a",
  null
  
)

component.options.__file = "localizacao.component.vue"
export default component.exports