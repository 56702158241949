import gql from "graphql-tag";

export const ContatoModel: any = {
    registrar: gql`
        mutation registrarContato(
            $nome: String!,
            $email: String!,
            $assunto: String!
            $mensagem: String!
        ) {
            registrarContato(
                nome: $nome,
                email: $email,
                assunto:$assunto,
                mensagem: $mensagem
            ) {
                status
                erro
            }
        }
    `
};
