var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
        'cms-imagem': true,
        'cms-imagem--center': _vm.alinhamento === 'center',
        'cms-imagem--left': _vm.alinhamento === 'left' && !_vm.mobile,
        'cms-imagem--right': _vm.alinhamento === 'right' && !_vm.mobile,
        'cms-imagem--fullwidth': _vm.tamanho === 0
    },style:({
        width: _vm.tamanhoImagem.width,
        height: _vm.tamanhoImagem.height,
        paddingTop: _vm.proporcaoTelaEdge
    })},[_c('figure',[(_vm.imagem.length)?_c('v-img',{class:{
                'cms-imagem__src': true,
                'cms-imagem__src--pointer': _vm.hasModal,
            },attrs:{"src":_vm.imagemAtual,"aspect-ratio":_vm.proporcaoTela,"width":_vm.tamanhoImagem.width,"height":_vm.tamanhoImagem.height}},[_c('v-layout',{attrs:{"slot":"placeholder","fill-height":"","align-center":"","justify-center":"","ma-0":""},slot:"placeholder"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1),(_vm.hasModal)?_c('v-layout',{staticClass:"cms-imagem__overlay",attrs:{"fill-height":"","align-start":"","justify-end":"","ma-0":""},on:{"click":_vm.mudaStatusModal}},[_c('v-btn',{staticClass:"cms-imagem__overlay__action",attrs:{"color":"#ffffff","outline":"","small":"","fab":""}},[_c('v-icon',{staticClass:"cms-imagem__overlay__action__icon"},[_vm._v("\n                        mdi-arrow-expand-all\n                    ")])],1)],1):_vm._e()],1):_vm._e(),(_vm.legenda.lenght)?_c('figcaption',{staticClass:"body-1 text--black darken-1 mt-2"},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("\n                mdi-camera\n            ")]),_vm._v("\n            "+_vm._s(_vm.legenda)+"\n        ")],1):_vm._e()],1),(_vm.hasModal)?_c('v-dialog',{attrs:{"scrollable":"","full-width":""},model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-img',{staticClass:"cms-imagem__dialog",attrs:{"src":_vm.imagem,"lazy-src":_vm.imagem,"aspect-ratio":16/9,"contain":""}},[_c('v-layout',{attrs:{"slot":"placeholder","fill-height":"","align-center":"","justify-center":"","ma-0":""},slot:"placeholder"},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }