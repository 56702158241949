import { render, staticRenderFns } from "./conteudos.component.vue?vue&type=template&id=49007504&scoped=true&"
import script from "./conteudos.component.ts?vue&type=script&lang=ts&"
export * from "./conteudos.component.ts?vue&type=script&lang=ts&"
import style0 from "./conteudos.component.sass?vue&type=style&index=0&id=49007504&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49007504",
  null
  
)

component.options.__file = "conteudos.component.vue"
export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib'
import { VBtn } from 'vuetify/lib'
import { VCard } from 'vuetify/lib'
import { VCardTitle } from 'vuetify/lib'
import { VContainer } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VIcon } from 'vuetify/lib'
import { VImg } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VProgressCircular } from 'vuetify/lib'
import { VResponsive } from 'vuetify/lib'
installComponents(component, {
  VAutocomplete,
  VBtn,
  VCard,
  VCardTitle,
  VContainer,
  VFlex,
  VIcon,
  VImg,
  VLayout,
  VProgressCircular,
  VResponsive,
})
