import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class BotaoComponent extends Vue
{
    @Prop({ type: String, default: "" })
    private link!: string;

    @Prop({ type: String, default: "Botão" })
    private texto!: string;

    @Prop({ type: String, default: "#333333" })
    private corTexto!: string;

    @Prop({ type: Boolean, default: false })
    private flat!: boolean;

    @Prop({ type: Boolean, default: false })
    private depressed!: boolean;

    @Prop({ type: Boolean, default: false })
    private outline!: boolean;

    @Prop({ type: Boolean, default: false })
    private round!: boolean;

    @Prop({ type: String, default: "medium" })
    private tamanho!: "small" | "medium" | "large";

    @Prop({ type: String, default: "left" })
    private alinhamento!: "left" | "center" | "right";

    @Prop({ type: String, default: "#ffffff" })
    private corBotao!: string;

    private isFile: boolean = false;
    private extension: string = "";
    private mobile: boolean = false;

    constructor()
    {
        super();
    }

    public mounted()
    {
        this.mobile = window.innerWidth < 960;

        this.isLinkFile();
        this.watchViewport();
    }

    private watchViewport():void
    {
        window.addEventListener("resize", ( event: UIEvent )=>
        {
            this.mobile = window.innerWidth < 960;
        });
    }

    private isLinkFile():void
    {
        let splitUrl: string[] = this.link.split("/");
        let urlLastIndex: string = splitUrl[splitUrl.length -1];
        let extArray: string[] = urlLastIndex.split(".");

        if ( extArray.length > 1 )
        {
            this.isFile = true;
            this.extension = extArray[extArray.length - 1];
        }
    }
}

Vue.component("BotaoComponent", BotaoComponent );
