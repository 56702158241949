import gql from "graphql-tag";

export let formFeedbackPositivoModel: any = {
    register: gql`
    mutation Atendimento(
            $feedback: String,
            $montante: Float,
            $atendimento: Boolean,
            $nps: Int,
            $id: ID!,
            $banco: [InstituicaoInput],
            $tipoResposta: String!)
        {
          atendimentoUpdate(id: $id, input: {
            feedback: $feedback
            montanteRecebido: $montante
            nps: $nps
            atendimento: $atendimento
            instituicoes: $banco
            feedbackRespondido: true
            tipoRespostaFeedback: $tipoResposta
          }) {
          ok
          atendimento {
            nome
          }
        }
      }
    `,
    instituicoes: gql`
            query {
                instituicoes{
                    edges{
                        node{
                            id
                            nome
                        }
                    }
                }
            }
    `
};
