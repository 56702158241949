import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class FooterComponent extends Vue
{
    @Prop({ type: Array, default: [] })
    private menurodape!: any;

    @Prop({ type: Array, default: [] })
    private rodapefinal!: any;

    private siteUrl: string = location.protocol + '//' + location.host;

    constructor()
    {
        super();
    }
}

Vue.component("FooterComponent", FooterComponent );
