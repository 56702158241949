import { Vue, Component } from "vue-property-decorator";
import { NewsletterModel } from "./newsletter.component.model";

@Component({
    $_veeValidate: {
        validator: "new"
    }
})
export default class NewsletterComponent extends Vue
{
    private nome: string = "";
    private email: string = "";

    private estadoEnvio: string = "info";
    private barraEstado: boolean = false;

    constructor()
    {
        super();
    }

    private enviar( event: Event ):void
    {
        event.preventDefault();

        this.$validator.validateAll()
        .then(( result: boolean ):void =>
        {
            if ( result )
            {
                this.estadoEnvio = "";

                this.$apollo.mutate({
                    mutation: NewsletterModel.registrar,
                    variables: {
                        nome: this.nome,
                        email: this.email
                    }
                })
                .then(( response: any ):void =>
                {
                    if (
                        response.data.hasOwnProperty("registrarAssinanteNewsletter") &&
                        !response.data.registrarAssinanteNewsletter.status
                    ) {
                        this.estadoEnvio = "info";
                    }
                    else
                    {
                        this.estadoEnvio = "success";
                    }

                    this.barraEstado = true;
                    this.limparFormulario();
                })
                .catch(( error: any ):void =>
                {
                    console.error( "NewsletterComponent enviar mutation", error );

                    this.estadoEnvio = "error";
                    this.barraEstado = true;
                });

                return;
            }

            this.estadoEnvio = "error";
            return;
        })
        .catch(( error: any ):void =>
        {
            console.error("NewsletterComponent enviar", error);

            this.estadoEnvio = "error";
            this.barraEstado = true;
        })
    }

    private limparFormulario():void
    {
        setTimeout(():void =>
        {
            this.$validator.reset();

            this.nome = "";
            this.email = "";

            this.estadoEnvio = "info";
            this.barraEstado = false;
        }, 6000);
    }
}

Vue.component("NewsletterComponent", NewsletterComponent );
