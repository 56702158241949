import { render, staticRenderFns } from "./header.component.vue?vue&type=template&id=fe80f234&scoped=true&"
import script from "./header.component.ts?vue&type=script&lang=ts&"
export * from "./header.component.ts?vue&type=script&lang=ts&"
import style0 from "./header.component.sass?vue&type=style&index=0&id=fe80f234&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fe80f234",
  null
  
)

component.options.__file = "header.component.vue"
export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VList } from 'vuetify/lib'
import { VListTile } from 'vuetify/lib'
import { VListTileTitle } from 'vuetify/lib'
import { VMenu } from 'vuetify/lib'
import { VSpacer } from 'vuetify/lib'
import { VToolbar } from 'vuetify/lib'
import { VToolbarItems } from 'vuetify/lib'
import { VToolbarSideIcon } from 'vuetify/lib'
import { VToolbarTitle } from 'vuetify/lib'
installComponents(component, {
  VBtn,
  VFlex,
  VLayout,
  VList,
  VListTile,
  VListTileTitle,
  VMenu,
  VSpacer,
  VToolbar,
  VToolbarItems,
  VToolbarSideIcon,
  VToolbarTitle,
})
