import { Vue, Component, Prop } from "vue-property-decorator";
import {defaultDataIdFromObject} from "apollo-cache-inmemory";

declare let window : any;

@Component
export default class HeaderComponent extends Vue
{

    private drawer: string | null = null;
    private siteUrl: string = location.protocol + '//' + location.host;
    private pathName: string = location.pathname;
    private mainMenu: any = window.MAIN_MENU[0];
    private showNavbar: boolean = true;
    private lastScrollPosition: any = 0;

    constructor()
    {
        super();
        window.addEventListener('scroll', this.onScroll);
    }

    protected onScroll () {
        const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
        if (currentScrollPosition < 0) {
          return
        }
        // Stop executing this function if the difference between
        // current scroll position and last scroll position is less than some offset
        if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60) {
          return
        }
        this.showNavbar = currentScrollPosition < this.lastScrollPosition
        this.lastScrollPosition = currentScrollPosition
      }    
}

Vue.component("HeaderComponent", HeaderComponent);