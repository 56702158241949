import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class LinhaCreditoComponent extends Vue
{

    @Prop({ default: [] })
    private info!: any;

    private nome: string = this.info.nome;
    private ativa: boolean = this.info.ativo;
    private descricao: string = this.info.descricao;
    private porte: Array<String> = this.info.porte;

    constructor()
    {
        super();
    }

}

Vue.component("LinhaCreditoComponent", LinhaCreditoComponent);