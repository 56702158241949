import gql from "graphql-tag";

export let formFeedbackNegativoModel: any = {
    register: gql`
    mutation Atendimento(
            $feedback: String!,
            $nps: Int,
            $id: ID!,
            $atendimento: Boolean,
            $tipoResposta: String!)
        {
        atendimentoUpdate(id: $id, input: {
          feedback: $feedback
          nps: $nps
          atendimento: $atendimento
          feedbackRespondido: true,
          tipoRespostaFeedback: $tipoResposta
        }) {
          ok
        }
      }
    `
};
