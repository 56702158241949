import { render, staticRenderFns } from "./nac-feedback-positivo.component.vue?vue&type=template&id=272c3981&"
import script from "./nac-feedback-positivo.component.ts?vue&type=script&lang=ts&"
export * from "./nac-feedback-positivo.component.ts?vue&type=script&lang=ts&"
import style0 from "./nac-feedback-positivo.component.sass?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "nac-feedback-positivo.component.vue"
export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib'
import { VBtnToggle } from 'vuetify/lib'
import { VCheckbox } from 'vuetify/lib'
import { VContainer } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VSelect } from 'vuetify/lib'
import { VSnackbar } from 'vuetify/lib'
import { VTextField } from 'vuetify/lib'
import { VTextarea } from 'vuetify/lib'
installComponents(component, {
  VBtn,
  VBtnToggle,
  VCheckbox,
  VContainer,
  VFlex,
  VLayout,
  VSelect,
  VSnackbar,
  VTextField,
  VTextarea,
})
