import { Vue, Component } from "vue-property-decorator";

@Component
export default class TextoRicoComponent extends Vue
{
    constructor()
    {
        super();
    }
}

Vue.component("TextoRicoComponent", TextoRicoComponent);
