import { Vue, Component, Prop } from "vue-property-decorator";
import { ConteudosModel } from "@/app/components/conteudos/conteudos.component.model";

@Component
export default class ConteudosComponent extends Vue
{
    @Prop({ type: Number, default: 0 })
    private idTipoConteudo!: number;

    private tipoConteudoTitulo: string = "";
    private tipoConteudoDescricao: string = "";

    private tiposCatetorias: object = {};
    private categoriasSelecionadas: string[] = [];
    private get categoriasSelecionadasNormalizadas():string[]
    {
        return this.categoriasSelecionadas
        .join().split(",")
        .filter(( item: string )=>
        {
            if ( item.length )
            {
                return item;
            }
        });
    }

    private conteudos: object[] = [];
    private get conteudosEstaVazio():boolean
    {
        return Object.keys( this.conteudos ).length < 1;
    }

    private ultimoCursor: string = "";
    private limite: number = 10;

    private carregarMais: boolean = true;
    private carregando: boolean = true;
    private primeiroCarregamento: boolean = true;

    constructor()
    {
        super();
    }

    public created():void
    {
        this.controladorCarregamento();
    }

    private async controladorCarregamento():Promise<void>
    {
        const tiposConteudos: Promise<void> = this.pegaTiposConteudos();
        const tiposCategorias: Promise<void> = this.pegaTiposCategorias();
        const conteudos: Promise<void> = this.pegaConteudos();

        await tiposConteudos;
        await tiposCategorias;
        await conteudos;

        this.primeiroCarregamento = false;
    }

    private pegaTiposConteudos():Promise<void>
    {
        return this.$apollo.query({
            query: ConteudosModel.pegaTiposConteudos,
            variables: {
                id: this.idTipoConteudo
            }
        })
        .then(
            ( resultados: any )=>
            {
                resultados = resultados.data.conteudoTipos.edges[0];

                this.tipoConteudoTitulo = resultados.node.nome;
                this.tipoConteudoDescricao = resultados.node.descricao;
            },
            ( erros: any )=>
            {
                console.error( erros );
                this.carregando = false;
            }
        )
        .catch(( erros: any )=>
        {
            console.error( erros );
            this.carregando = false;
        });
    }

    private pegaTiposCategorias():Promise<void>
    {
        return this.$apollo.query({
            query: ConteudosModel.pegaTiposCategorias,
            variables: {
                tipoConteudo: this.idTipoConteudo
            }
        })
        .then(
            ( resultados: any )=>
            {
                resultados = resultados.data.conteudoTiposCategoria.edges;

                for(
                    let index: number = 0;
                    index < resultados.length;
                    index++
                ) {
                    let itens: any = resultados[index].node;
                    let categorias: object[] = [];

                    for (
                        let subindex: number = 0;
                        subindex < itens.categorias.edges.length;
                        subindex++
                    ) {
                        let subitens: any = itens.categorias.edges[subindex];
                        categorias.push({
                            id: subitens.node.pk,
                            nome: subitens.node.nome
                        });
                    }

                    if ( categorias.length )
                    {
                        this.tiposCatetorias = {
                            ...this.tiposCatetorias,
                            [itens.pk]: {
                                id: itens.pk,
                                nome: itens.nome,
                                categorias: categorias
                            }
                        };
                    }
                }
            },
            ( erros: any )=>
            {
                console.error( erros );
                this.carregando = false;
            }
        )
        .catch(( erros: any )=>
        {
            console.error( erros );
            this.carregando = false;
        });
    }

    private async pegaConteudos( possuiFiltro: boolean = false ):Promise<void>
    {
        await Vue.nextTick();

        this.carregando = true;

        if ( possuiFiltro )
        {
            this.ultimoCursor = "";
        }

        let promise: Promise<void> = await <Promise<any>>this.$apollo.query({
            query: ConteudosModel.pegaConteudos,
            variables: {
                categorias: this.categoriasSelecionadasNormalizadas,
                tipos: [this.idTipoConteudo],
                limite: this.limite,
                cursor: this.ultimoCursor
            }
        })
        .then(
            ( resultados: any )=>
            {
                resultados = resultados.data.conteudo;

                this.carregarMais = resultados.pageInfo.hasNextPage;
                this.ultimoCursor = resultados.pageInfo.endCursor;

                if ( possuiFiltro )
                {
                    this.conteudos = [];
                }

                for(
                    let index: number = 0;
                    index < resultados.edges.length;
                    index++
                ) {
                    let conteudo: object = resultados.edges[index];
                    this.conteudos = [
                        ...this.conteudos,
                        (conteudo as any).node
                    ]
                }

                this.carregando = false;
            },
            ( erros: any )=>
            {
                console.error( erros );
                this.carregando = false;
            }
        )
        .catch(( erros: any )=>
        {
            console.error( erros );
            this.carregando = false;
        });

        return promise;
    }
}

Vue.component("ConteudosComponent", ConteudosComponent);
