import { render, staticRenderFns } from "./galeria.component.vue?vue&type=template&id=bdf655e2&"
import script from "./galeria.component.ts?vue&type=script&lang=ts&"
export * from "./galeria.component.ts?vue&type=script&lang=ts&"
import style0 from "./galeria.component.sass?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "galeria.component.vue"
export default component.exports