import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class LinkComponent extends Vue
{
    @Prop({ type: String, default: "" })
    private url!: string;

    @Prop({ type: String, default: "" })
    private texto!: string;

    private finalUrl: string = "";

    constructor()
    {
        super();
    }

    public mounted()
    {
        this.constroiProtocolo();
    }

    private constroiProtocolo():void
    {
        if (
            this.url.length &&
            !this.dominioLocal() &&
            !this.possuiProtocolo()
        ) {
            this.insereHttp();
        }
        else
        {
            this.finalUrl = this.url;
        }
    }

    private possuiProtocolo():boolean
    {
        let possuiHttp: number = this.url.indexOf("http://");
        let possuiHttps: number = this.url.indexOf("https://");

        return possuiHttp !== -1 || possuiHttps !== -1;
    }

    private dominioLocal():boolean
    {
        return this.url.indexOf("/") === 0 || this.url.indexOf(window.location.origin) === 0;
    }

    private eLinkExterno():string
    {
        if ( this.dominioLocal() )
        {
            return "_self";
        }
        else
        {
            return "_blank";
        }
    }

    private insereHttp():void
    {
        this.finalUrl = `http://${this.url}`;
    }
}

Vue.component( "LinkComponent", LinkComponent );
