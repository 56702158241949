import { Vue, Component, Prop } from "vue-property-decorator";
import {defaultDataIdFromObject} from "apollo-cache-inmemory";

@Component
export default class CardsComponent extends Vue
{
    @Prop({ type: Array, default: "" })
    private cards!: any;

    constructor()
    {
        super();
    }
}

Vue.component("CardsComponent", CardsComponent);