import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class GaleriaSlideComponent extends Vue
{
    @Prop({ type: String, default: "https://picsum.photos/1920/1080?random" })
    private imagem!: string;

    @Prop({ type: String, default: "https://picsum.photos/1920/1080?random" })
    private imagemMobile!: string;

    @Prop({ type: String, default: "Lorem ipsum dollor sit amet." })
    private legenda!: string;

    private get imagemAtual():string
    {
        if ( this.$vuetify.breakpoint.mdAndDown )
        {
            return this.imagemMobile;
        }

        return this.imagem;
    }

    constructor()
    {
        super();
    }

    public created()
    {

    }
}

Vue.component("GaleriaSlideComponent", GaleriaSlideComponent);
