import { Vue, Component, Prop } from "vue-property-decorator";
import {defaultDataIdFromObject} from "apollo-cache-inmemory";

@Component
export default class NacBeneficiosComponent extends Vue
{
    protected lists: any = [
        {
            id: 1,
            icone: "icons/icon-confianca.svg", 
            item: "Credibilidade", 
            texto: "Fazemos parte da <b>Confederação Nacional da Indústria (CNI)</b>, que desde a sua fundação, em 1938, defende os interesses da indústria nacional."
        },
        {
            id: 2,
            icone: "icons/icon-neutralidade.svg", 
            item: "Benefícios e transparência", 
            texto: "Recomendamos as linhas de crédito mais vantajosas para a sua empresa. Apesar de não possuirmos vínculos permanentes com instituições financeiras, temos acordos de cooperação que oferecem benefícios às empresas que procuram o NAC. Caso haja alguma parceria vigente, apresentamos as vantagens ao empresário, sem obrigatoriedade de adesão."
        },
        {
            id: 3,
            icone: "icons/icon-objetividade.svg", 
            item: "Clareza e Objetividade", 
            texto: 'Nossos cursos e capacitações possuem informações claras e objetivas, a fim de dar aos empresários os subsídios necessários na hora de escolher qual é a melhor linha de crédito para seu negócio'
        },
        {
            id: 4,
            icone: "icons/icon-Brasil.svg", 
            item: "Presença Nacional", 
            texto: "Nossa equipe está presente nas federações das Indústrias de 24 estados brasileiros."
        }
    ]

    constructor()
    {
        super();
        
    }
}

Vue.component("NacBeneficiosComponent", NacBeneficiosComponent);
