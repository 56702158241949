import { Vue, Component, Prop } from "vue-property-decorator";
import TituloComponent from "@/app/components/titulo/titulo.component";

@Component
export default class VideoComponent extends Vue
{
    @Prop({ type: String, default: "" })
    private video!: string;

    @Prop({ type: String, default: "" })
    private imagem!: string;

    @Prop({ type: String, default: "" })
    private legenda!: string;

    @Prop({ type: Number, default: 500 })
    private larguraCustomizada!: number;

    @Prop({ type: Number, default: 16/9 })
    private aspectRatio!: number;

    @Prop({ type: Number, default: 0 })
    private tamanho!: 0 | 1;

    @Prop({ type: String, default: "center" })
    private alinhamento!: "left" | "center" | "right";

    @Prop({ type: Boolean, default: true })
    private hasModal!: boolean;

    private viewSize: number = 0;
    private modal: boolean = false;

    constructor()
    {
        super();
    }

    public mounted()
    {
        this.watchViewport();
        this.aspectRatioEdge();
    }

    private mudaStatusModal():void
    {
        this.modal = !this.modal;
    }

    private watchViewport():void
    {
        window.addEventListener("resize", ( event: UIEvent )=>
        {
            this.viewSize = window.innerWidth;
        });
    }

    private aspectRatioEdge():string
    {
        if ( window.navigator.userAgent.indexOf("Edge") > -1 )
        {
            return `${(1080 / 1920) * 100}%`
        }

        return "0px";
    }

    private mudaLarguraImagem():string
    {
        if ( this.tamanho === 0 || this.viewSize < 960 )
        {
            return "100%";
        }
        else
        {
            return `${this.larguraCustomizada}px`;
        }
    }
}

Vue.component("VideoComponent", VideoComponent );